import { createContext, useState, useEffect } from 'react';


//import { utilMethods } from '../util/utilMethods';
import { firestoreMethods } from './firestoreMethods';

export const firestoreAudits = createContext();

const AuditProvider = (props) => {

  const [audit, setAudit] = useState();
  //const [auditSteps, setAuditSteps] = useState();

  const [refreshAudit, setRefreshAudit] = useState(false);
  const [refreshAuditSteps, setRefreshAuditSteps] = useState(true);
  const [nextSliceID, setNextSliceID] = useState();

  
  const [viewAudits, setViewAudits] = useState(null);
  const [viewAllSteps, setViewAllSteps] = useState(null);

  const [refreshViewAudits, setRefreshViewAudits] = useState(true);

  //============= bakery
  const [bakeryStepDefs, setBakeryStepDefs] = useState(null);
  const [bakerySteps, setBakerySteps] = useState(null);

  const isAdmin = window.location.pathname.toLowerCase().startsWith('/admin');
  const isBaker = window.location.pathname.toLowerCase().startsWith('/baker');

  //const location = useLocation();
  //console.log('LOCATION2: ', location.pathname === '/admin')

  // refresh ALL AUDITS
  useEffect(() => {

    const getNextSlice = (audits) => {
      //console.log('AUDITS', audits);
      let highest = 0;
      for (const a of audits) {
        const aS = Number(a.sliceID || 0)
        highest = Math.max(highest, aS);
      }
      //console.log(highest);
      return (highest+1).toString();
    }

    const fetchData = async () => {
      console.log("UE: refresh ALL AUDITS")
      const filter = {name: 'sliceID', op: '>', value: '5'}
      const newData = await firestoreMethods.get("audits", filter);
      //const sortedNewData = utilMethods.sortByDate(newData);
      //console.log("sortedNewData", sortedNewData);
      setViewAudits(newData);

      // GET ALL STEPS
      // TODO: turning off for performance for now
      //const stepData = await firestoreMethods.get("asteps");
      //setViewAllSteps(stepData);
      setViewAllSteps({});

      setRefreshViewAudits(false);
      const nextSlice = getNextSlice(newData);
      console.log("nextSlice", nextSlice);
      setNextSliceID(nextSlice);



      //console.log("AuditProvider: viewAudits: ", newData);
    };
    if (isAdmin && refreshViewAudits) fetchData();
  }, [isAdmin, refreshViewAudits]); // Trigger when refreshViewAudits changes

  // refresh single audit
  useEffect(() => {

    const fetchData = async () => {
      console.log("UE: refresh AUDIT")

      const newData = await firestoreMethods.getD("audits", audit.id);
      setAudit(newData);

      // update this audit in viewAudits (without refetching)
      const objectIndex = viewAudits.findIndex((a) => a.id === audit.id);
      // Check if object is found
      if (objectIndex !== -1) {
        // Create a copy of the array
        const newArray = [...viewAudits];

        // Replace the object at the index with the updated object
        newArray[objectIndex] = newData;

        // Update the state with the new array
        setViewAudits(newArray);

      } else {
        console.error('Object not found in the array');
      }

      setRefreshAudit(false);
    };

    if (isAdmin && refreshAudit) fetchData();
  }, [isAdmin, refreshAudit, viewAudits, audit?.id]);

  // ======================== IS BAKER ========================
  // ======================== IS BAKER ========================
  // ======================== IS BAKER ========================
  useEffect(() => {

    const fetchData = async () => {
      console.log("UE: is baker")
      //TODO later optimize and retrieve only bakery stepsconst filter = {name: 'id', op: '>=', value: 'BAKER'}
      const btypesData = await firestoreMethods.get("bakery");
      console.log('BAKER', btypesData);
      const btypes = btypesData.filter(bt => bt.id.startsWith('BAKER'));
      setBakeryStepDefs(btypes);

      //const bstepsFilter = {name: 'eFunc', op: '==', value: 'createException'}
      const bstepsFilter = {name: 'baker', op: '==', value: true}
      const bstepsData = await firestoreMethods.get("asteps", bstepsFilter);
      console.log('STEPS', bstepsData);
      setBakerySteps(bstepsData);

    };

    if (isBaker) fetchData();
  }, [isBaker]);

  // ======================== UPDATE STEPS ========================
  const updateBakerySteps = async (updateId, updateStep) => {
    const objectIndex = bakerySteps.findIndex((s) => s.id === updateId);
    // Check if object is found
    if (objectIndex !== -1) {
      const newArray = [...bakerySteps];       // Create a copy of the array
      newArray[objectIndex] = updateStep;       // Replace the object at the index with the updated object
      setBakerySteps(newArray);       // Update the state with the new array
    } else {
      console.error('Object not found in the array');
    }
  }

  // ======================== RETURN ========================
  // ======================== RETURN ========================
  // ======================== RETURN ========================


  return (
    <firestoreAudits.Provider
    value={{
      viewAudits,
      viewAllSteps,
      setRefreshViewAudits,
      refreshAuditSteps,
      setRefreshAuditSteps,
      audit, 
      setAudit,
      nextSliceID,
      setRefreshAudit,
      bakeryStepDefs,
      bakerySteps,
      updateBakerySteps,
    }}>
      {props.children}
    </firestoreAudits.Provider>
    
  );
};

export default AuditProvider;


