// util helper methods go here... 

//import { firestoreMethods } from '../firebase/firestoreMethods';


export const stepValidationMethods = {

  "NEW": (step) => {
    console.log("in NEW validation", step);
    //const isPolicyUploaded = step.atts && step.atts.length > 0 && (step.atts.some((doc) => doc.tag === 'D1')); 
    //return isPolicyUploaded;
    return {status: "200" };
  },
  "AGENT_EMAIL_KICKOFF": (step) => {
    console.log("in AGENT_EMAIL_KICKOFF validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "AGENT_EMAIL_INSURED_BOUNCED": (step) => {
    console.log("in AGENT_EMAIL_INSURED_BOUNCED validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "AGENT_EMAIL_PROVIDED_AGENT": (step) => {
    console.log("in AGENT_EMAIL_PROVIDED_AGENT validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "WAITING_AGENT": (step) => {
    console.log("in WAITING_AGENT validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  }, 
  "WAITING_PROVIDED_AGENT": (step) => {
    console.log("in WAITING_PROVIDED_AGENT validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  }, 

  "MAILING_INSURED": (step) => {
    console.log("in MAILING INSURED validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "MAILING_INSURED_LOCATION": (step) => {
    console.log("in MAILING_INSURED_LOCATION validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  
  "CALLING_INSURED": (step) => {
    console.log("in CALLING_INSURED validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "CALLING_AGENT": (step) => {
    console.log("in CALLING_AGENT validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "CALLING_INSURED_2X": (step) => {
    console.log("in CALLING_INSURED_2X validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "TEXTING_INSURED": (step) => {
    console.log("in TEXTING_INSURED validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "INSURED_EMAIL_NEW_CONTACT": (step, audit) => {
    console.log("in INSURED_EMAIL_NEW_CONTACT validation", step);
    if (audit.agentInsuredEmail) {
      return {status: "200" };
    } else {
      return {status: "301", message: "no agentInsuredEmail" };
    }
  },
  "EMAILING_INSURED": (step) => {
    console.log("in EMAILING_INSURED validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "EMAILING_INSURED_2X": (step) => {
    console.log("in EMAILING_INSURED_2X validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "INSURED_EMAIL_3X": (step) => {
    console.log("in INSURED_EMAIL_3X validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "INSURED_EMAIL_SUBMIT_DOCS": (step) => {
    console.log("in INSURED_EMAIL_SUBMIT_DOCS validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "INSURED_EMAIL_WRONG_DOC": (step) => {
    console.log("in INSURED_EMAIL_WRONG_DOC validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "WAITING_INSURED": (step) => {
    console.log("in WAITING_INSURED validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "RECEIVING_INFO": (step) => {
    console.log("in RECEIVING_INFO validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "RECEIVING_AGENT_INFO": (step) => {
    console.log("in RECEIVING_AGENT_INFO validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "RECEIVING_PROVIDED_AGENT_INFO": (step) => {
    console.log("in RECEIVING_PROVIDED_AGENT_INFO validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "EMAILING_INSURED_DONE": (step) => {
    console.log("in EMAILING_INSURED_DONE validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "MARK_UNPRODUCTIVE": (step) => {
    console.log("in MARK_UNPRODUCTIVE validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "SENT_TO_USLI_API": (step) => {
    console.log("in SENT_TO_USLI_API validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "SENT_TO_CARRIER": (step) => {
    console.log("in SENT_TO_CARRIER validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },
  "MANUAL": (step) => {
    console.log("in MANUAL validation", step);
    // TODO: CHECK CALL HAPPENED?
    return {status: "200" };
  },

  

  
}