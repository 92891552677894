
const LoadingLogo = () => {
  return (
    <div className="modal-overlay purple-1">
      <div className="modal-content center">
        <div>... LOADING ...</div>
      </div>
    </div>
  );
};

export default LoadingLogo;