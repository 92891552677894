//================= LETTER INSURED
//================= LETTER INSURED
//================= LETTER INSURED


export const letterInsuredTemplate = (variables) => `
<html style="padding-top: 3in; margin: .5in;">
<div style="text-align: center; font-size: 12px; margin: 0 auto; width: 600px">
	<h1>Insurance Premium Audit Notification</h1>
	
	<div style="margin-top: 20px;text-align: left;">
		<span>Your insurance policy has expired and requires a routine audit.</span>
	</div>
	<div style="margin-top: 20px;text-align: left;">
		<span>Failure to comply with this request for an Audit will result in an estimated audit being processed and may jeopardize your insurance coverage with Mount Vernon Fire Insurance Company, United States Liability Insurance Company, and U.S. Underwriters Insurance Company.</span>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<div><b>Insured Name:</b> ${variables.insuredCompany}</div>
		${(variables.insuredDbaName) && ('<div><b>Insured DBA Name:</b> '+variables.insuredDbaName + '</div>')}
		<div><b>Policy Number</b>: ${variables.policyNumber}</div>
		<div><b>Policy Expiration</b>: ${variables.policyEnd}</div>
	</div>

	<div style="margin-top: 20px;text-align: left;">
		<span>If you have already completed your audit, please disregard this message.
	</div>

	<div style="margin: 30px; border: 1px solid black">
		<table style="border:none;">
			<tr>
				<td style="border:none;">
					<img width="100px" src="https://firebasestorage.googleapis.com/v0/b/amp-amp.appspot.com/o/ac-qr-code.png?alt=media&token=785c2508-2af6-4b37-9ef2-a92657f6bb5b" alt="QR" />
				</td>
				<td style="border:none; padding-left: 30px; text-align:left; vertical-align: middle">
					<div>Please visit</div>
					<div style="color: #005EB8; font-size: 16px;">https://auditcake.com</div>
			    <div>or scan the QR code to the left.</div>

			    <div style="font-size: 16px; margin-top: 20px;">YOUR AUDIT CODE IS: <b style="color: #8B0000">${variables.auditCode}</b></div>

				</td>
			</tr>
		</table>
	</div>

		

	<div>
		<span style='margin-top: 5px; font-size: 10px;'>SML Capital Advisors has been contracted by ${variables.carrierCompany} to perform this premium audit.</span>
		<br/>
		<span style='font-size: 10px;'>If you have any questions, please email <b>sml@auditcake.com</b> or call <b>(917) 725-1519.</b></span></p>
	</div>

	<div style="margin-top: 15px; border-top: 1px solid #ccc;"/>
		
	<div style="padding-top: 20px;">
	  <img width="100px" src="https://firebasestorage.googleapis.com/v0/b/amp-amp.appspot.com/o/SMLLogo.png?alt=media&token=f4dd7f1d-388f-40cc-bcff-57904c1091f1" alt="Image" />
	</div>
</div>
</html>
`;