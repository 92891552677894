export const txtTemplate = (variables) => `
Your policy # ${variables.policyNumber} with ${variables.carrierCompany} expired on ${variables.policyEnd}, and requires an audit. Please complete this audit ASAP, to ensure your policy reflects actual exposures and is priced accurately. This process should only take 8 minutes to complete. Your audit confirmation code is ${variables.auditCode} and can be completed here auditcake.com/SML/${variables.auditCode}
`;

export const txtTemplate2 = (variables) => `
This is an automated message about your USLI insurance policy # ${variables.policyNumber} for ${variables.insuredCompany}, which expired on ${variables.policyEnd}.  An audit is required ASAP, to ensure your policy reflects actual exposures and is priced accurately.
 
Your audit can now be completed online at auditcake.com/${variables.auditCode}.  ${variables.auditCode} is your audit code.

Press 1 to CONFIRM. Press 2 to send the audit link to your EMAIL. Press 3 to invite your ACCOUNTANT.
`