import { functions } from "./firebaseApp";
import { httpsCallable } from "firebase/functions";


// firebase functions helper methods go here... 

export const functionsMethods = {

  sendGridEmail: async (to = "ilya.mezheritsky@gmail.com", cc, subject = "Message from SML", message, auditCode, ref, auditFSID) => {
    // TODO: don't forget to change security in GCP
    console.log("sendGridEmail");
    const sendGridEmail = httpsCallable(functions, "sendGridEmailCall");
    const success = await sendGridEmail({ to: to, cc: cc, subject: subject, message: message, auditCode: auditCode, ref: ref, auditFSID:auditFSID});
    console.log("SUC:", success);
  },
/*
  twilioCall: async (to = "9739454946", message = "No Message") => {
    // TODO: don't forget to change security in GCP
    console.log("twilioCall");
    const sendGridEmail = httpsCallable(functions, "twilioCall");
    const success = await sendGridEmail({ to: to, message: message });
    console.log("SUC:", success);
  },
  */

  twilioMakeCallOnCall: async (toPhone) => {
    const twilioMakeCallOnCall = httpsCallable(functions, "twilioMakeCallOnCall");
    const success = await twilioMakeCallOnCall({toPhone});
    console.log("SUCTW:", success);
    return success.data;
  },

  twilioGetCallRecordingsOnCall: async(callSid) => {
    const twilioGetCallRecordingsOnCall = httpsCallable(functions, "twilioGetCallRecordingsOnCall");
    const success = await twilioGetCallRecordingsOnCall({callSid});
    console.log("SUCcess recordings:", success);
    return success.data;
  },
/*
  twilioGetToken: async () => {
    const twilioGetToken = httpsCallable(functions, "twilioGetToken");
    const success = await twilioGetToken({});
    console.log("SUC:", success);
    return success;
  },
*/

  getQBOIncomeStatement: async (auditFSID) => {
    console.log("QBO", auditFSID);
    const getQBOIS = httpsCallable(functions, "getQBOIS");
    const success = await getQBOIS({auditFSID:auditFSID});
    console.log("getQBOIS SUCCESS:", success);
    return success.data;
  },

  sendLOBLetter: async (letter, mailProps) => {
    console.log("LOB");
    const getLob = httpsCallable(functions, "sendLobLetterCall");
    const success = await getLob({letter:letter, mailProps:mailProps});
    console.log("getLOB SUCCESS:", success);
    return success.data;
  },

  convertToPdf: async (fileName, processedData) => {
    console.log("convertToPdf", fileName, processedData);
    const convert = httpsCallable(functions, "convertPdf");
    const success = await convert({fileName, processedData});
    console.log("CONVERSION SUCCESS:", success);
    return success.data;
  },

  convertImageToPdf: async (fileName) => {
    console.log("convertImageToPdf", fileName);
    const convertImagePdf = httpsCallable(functions, "convertImagePdf");
    const success = await convertImagePdf({fileName});
    console.log("CONVERSION SUCCESS:", success);
    return success.data;
  },

  determineNextStep: async (audit, executeNextSteps) => {
    console.log("determineNextStep", audit.id, executeNextSteps);
    const determineNextStep = httpsCallable(functions, "bake_determineNextStepOnCall");
    const success = await determineNextStep({audit: audit, executeNextSteps: executeNextSteps});
    console.log("NEXT STEP SUCCESS:", success);
    return success.data;
  },

  sendUsli: async (xmlFilePath, finalFilePath, {cost, outcome}) => {
    console.log("sendUsli", xmlFilePath, finalFilePath, cost, outcome);
    const sendUsli = httpsCallable(functions, "sendUsli");
    const success = await sendUsli({xmlFilePath, finalFilePath, cost, outcome});
    console.log("SYNC SUCCESS:", success);
    return success.data;
  },

  attSummarize: async (args) => {
    console.log("attSummarize", args);
    try {
      const attSummarizeRef = httpsCallable(functions, "attSummarizeCall");
      const success = await attSummarizeRef(args);
      console.log("ATT SUMMARIZE SUCCESS:", success);
      return success;
    } catch (error) {
      console.error("Error parsing inner JSON string:", error);
      return "Error in att Summarize";
    }
  },

  getMarbleReport: async () => {
    console.log("getMarbleReport");
    const marbleReport = httpsCallable(functions, "marbleReportUI");
    const success = await marbleReport();
    console.log("SYNC SUCCESS:", success);
    return success.data;
  },

  scanPolicy: async (url) => {
    console.log("ScanPolicy", url);
    const scanPolicy = httpsCallable(functions, "policyScan");
    const success = await scanPolicy({url: url});
    console.log("POLICY SCAN SUCCESS:", success);
    if (success.data !== 'Error in Scan Policy') { 

      // Try to parse the inner JSON string
      try {
        const innerJsonString = success.data.gResult.split('`json\n')[1].split('\n`')[0];
        const data = JSON.parse(innerJsonString);
        console.log("Data", data);

        //const innerJsonString2 = success.data.eResult.split('`json\n')[1].split('\n`')[0];
        //const data2 = JSON.parse(innerJsonString2);
        //console.log("Data2", data2);

        return data;
        //const outerDict = { data };
        //console.log(JSON.stringify(outerDict, null, 4)); // Print the converted JSON with indentation
        //return JSON.stringify(outerDict, null, 4);

      } catch (error) {
        console.error("Error parsing inner JSON string:", error);
        return "Error in Scan Policy";
      }
    } else {
      return success.data
    }

  },


    /*
  sendCloudEmail: async (to = "ilya.mezheritsky@gmail.com", subject = "Message from SML", message) => {
    // todo: add validation
    console.log("SendCloudEmail");
    const from = "ilya.mezheritsky@gmail.com";
    const messageToEncode = "From: "+from+"\nTo: "+to+"\nSubject: "+subject+"\nContent-Type: text/html; charset=UTF-8\n\n\n"+message+"";
    const encodedMessage = btoa(messageToEncode)
    const reallyEncodedMessage = encodedMessage.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
  
    const sendEmail = httpsCallable(functions, "sendEmail");
    const success = await sendEmail({ message: reallyEncodedMessage });
    console.log("SUC:", success);
  },
  */
  
/*
  scanPolicy: async (url) => {
    console.log("ScanPolicy", url);
    const scanPolicy = httpsCallable(functions, "scanPolicy");
    const success = await scanPolicy({url: url});
    console.log("POLICY SCAN SUCCESS:", success);
    if (success.data !== 'Error in Scan Policy') { 

      // Try to parse the inner JSON string
      try {
        const innerJsonString = success.data.gResult.split('`json\n')[1].split('\n`')[0];
        const data = JSON.parse(innerJsonString);
        console.log("Data", data);

        //const innerJsonString2 = success.data.eResult.split('`json\n')[1].split('\n`')[0];
        //const data2 = JSON.parse(innerJsonString2);
        //console.log("Data2", data2);

        return data;
        //const outerDict = { data };
        //console.log(JSON.stringify(outerDict, null, 4)); // Print the converted JSON with indentation
        //return JSON.stringify(outerDict, null, 4);

      } catch (error) {
        console.error("Error parsing inner JSON string:", error);
        return "Error in Scan Policy";
      }
    } else {
      return success.data
    }

  },

  scanPL: async (url, inputs) => {
    console.log("ScanPL", url, inputs);
    const scanPL = httpsCallable(functions, "scanPL");
    const success = await scanPL({url: url, inputs:inputs});
    console.log("PL SCAN SUCCESS:", success);

    if (success.data !== 'Error in Scan Policy') { 

      // Try to parse the inner JSON string
      try {
        const innerJsonString = success.data.split('`json\n')[1].split('\n`')[0];
        const data = JSON.parse(innerJsonString);
        console.log("Data", data);

        return data;
        //const outerDict = { data };
        //console.log(JSON.stringify(outerDict, null, 4)); // Print the converted JSON with indentation
        //return JSON.stringify(outerDict, null, 4);

      } catch (error) {
        console.error("Error parsing inner JSON string:", error);
        return "Error in Scan Policy";
      }
    } else {
      return success.data
    }

  },

  findPL: async (url, q) => {
    console.log("FindPL", url, q);
    const findPL = httpsCallable(functions, "findPL");
    const success = await findPL({url: url, q:q});
    console.log("PL FIND SUCCESS:", success);
    return success.data
  },

  scanto: async () => {
    console.log("scan2");
    const scanto = httpsCallable(functions, "scanto");
    const success = await scanto();
    console.log("SCAN2 SUCCESS:", success);
    return success.data;
  },
  */
/*
  updateNexus: async () => {
    // todo: add validation
    console.log("Update Nexus");
    
    const updateNexus = httpsCallable(functions, "updateNexus");
    const success = await updateNexus({ message: "hello" });
    console.log("Nexus:", success);
  },
  */
}