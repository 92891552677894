import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
//import { getFirestore } from 'firebase/firestore';

import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  appId: process.env.REACT_APP_APP_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
//const analytics = getAnalytics(app);
const storage = getStorage(app);
const auth = getAuth();
//const listRef = ref(storage, 'gs://all-the-spots.appspot.com/2023_03_07/esA4x8SGl36czsfciAIy/');

const functions = getFunctions(app);

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001); //npm run serve to startup, or firebase emulators:start
  connectFirestoreEmulator(db, 'localhost', 8080); // firebase emulators:start --only firestore
  connectStorageEmulator(storage, 'localhost', 9199);
  //connectAuthEmulator(auth, 'localhost', 9099);
  //connectAuthEmulator(auth, 'http://127.0.0.1:9099');
  connectAuthEmulator(auth, "http://localhost:9099");
}

//console.log('ENV', process.env);

//const hw = httpsCallable(functions, "helloWorld");
//const success = await hw({ text: "messageText" });
//console.log("success yes: ", success);

export  {
   db as default, 
   storage,
   functions,
   auth,
}
