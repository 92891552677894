import { createContext, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { useAuthState } from "react-firebase-hooks/auth";

import { authMethods } from './authMethods';
//import { utilMethods } from '../util/utilMethods';

//import auth from "./firebaseApp";


export const firebaseAuth = createContext();

const AuthProvider = (props) => {
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);
  const [debug, setDebug] = useState(false);

  const signInWithGoogle = (setErrors) => {
    authMethods.signInWithGoogle(setErrors);
  }

  const signOut = () => {
    authMethods.signOut();
  }

  //const prodList = ['HFGYJS','YJ8GAX']

  

  return (
    <firebaseAuth.Provider
    value={{
      user,
      loading,
      signInWithGoogle,
      signOut,
      debug,
      setDebug,
    }}>
      {props.children}
    </firebaseAuth.Provider>
    
  );
};

export default AuthProvider;
//export default withRouter(AuthProvider);


