//https://cloud.google.com/text-to-speech/docs/ssml?hl=en

export const callTemplate2X = (variables) => `
<speak>
  <p>
    Hi ${variables?.insuredFirstName}.
  </p>
  <p> 
    This is a second call about your insurance policy ${variables?.policyNumber} 
    with ${variables?.carrierCompany} expiring on ${variables?.policyEnd}. 
  </p>
  <p>
    You are required to conduct a premium audit to comply with state law and to 
    prevent surcharges on your policy. 
    Your audit confirmation code is 
    <say-as interpret-as="characters">${variables?.auditCode}</say-as>, 
    please visit AuditCake.com to complete this mandatory audit. 
  </p>
  <p>
    Press 1 to repeat, Press 2 to send an SMS link to this number,
    or Press 3 to send to another mobile number. 
  </p>
</speak>
`;