function formatDateString(dateString) {
  // Validate input type
  if (typeof dateString !== 'string') {
    throw new TypeError('Input must be a string');
  }

  // Create a Date object from the string
  const date = new Date(dateString);

  // Check if date object is valid (avoid errors for invalid formats)
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date format in string');
  }

  // Get month name, day, and ordinal suffix using Date object methods
  const monthNames = ["January", "February", "March", "April", "May", "June",
                      "July", "August", "September", "October", "November", "December"];
  const month = monthNames[date.getMonth()]; // Zero-based month index
  const day = date.getDate(); // Day of the month
  const suffix = getOrdinalSuffix(day);

  // Return formatted string with month name, day, and suffix
  return `${month} ${day}${suffix}`;
}

// Function to determine ordinal suffix (st, nd, rd, th)
function getOrdinalSuffix(day) {
  const ones = day % 10;
  const tens = Math.floor(day / 10) % 10;

  if (tens === 1) {
    return 'th';
  } else {
    switch (ones) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}

export const callAgentTemplate = (variables) => `
<speak>
  <p>
    Hi ${variables?.agentFirstName}.
  </p>
  <br/>
  <p> 
    Your client ${variables?.insuredCompany} has an upcoming audit   
    for their policy with ${variables?.carrierCompany} that expires on ${formatDateString(variables?.policyEnd)}. 
    Please help your client complete this audit by ${formatDateString(variables?.auditDue)}.
    Based on other similar audits, it takes 8 minutes to complete. 
  </p>
  <br/>
  <p>
    The audit confirmation code is ${variables?.auditCode.split('').join('...')}.  
    Your client will need this to complete the audit.
    Once again, that code is ${variables?.auditCode.split('').join('...')}. 
  </p>
  <br/>
  <p>
    Please visit auditcake.com/SML/${variables?.auditCode.split('').join('...')} to complete the audit.
  </p>
  <p>Thank you.  Good Bye</p>
</speak>
`;